body {
    background-color: #000002;
    font-family: 'Roboto', "Segoe UI", "Arial", "Helvetica", sans-serif;
    cursor: default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

@media only screen and (min-width: 900px) {
    .cursor {
        width: 1rem;
        height: 1rem;
        background: #0b91db;
        border-radius: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        top: var(--x, 100px);
        left: var(--y, 100px);
        transition: all 0.6s ease;
        transition-property: background, transform;
        transform-origin: 100% 100%;
        z-index: 70;
        pointer-events: none;
        cursor: default;
    }

    .cursor-grow {
        background: white;
        border-radius: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        top: var(--x, 100px);
        left: var(--y, 100px);
        pointer-events: none;
        transition: all 0.6s ease;
        transition-property: background, transform;
        transform-origin: 100% 100%;
        z-index: 70;
        animation: cursorGrow 0.2s ease-out forwards;
    }

    .cursor-grow::after {
        width: 100%;
        height: 100%;
        background: white;
        position: absolute;
        border-radius: 50%;
        animation: ripple 0.4s ease-out;
        transform-origin: center center;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: rgba(11, 145, 219, 0.5);
        content: "View";
        font-family: "Poppins", sans-serif;
    }

    @keyframes cursorGrow {
        0% {
            width: 1rem;
            height: 1rem;
        }
        100% {
            width: 6.2rem;
            height: 6.2rem;
        }
    }

    @keyframes ripple {
        0% {
            transform: scale(0);
            background: rgba(11, 145, 219, 0.5);
        }
        20% {
            transform: scale(0);
            opacity: 0.1;
            background: rgba(11, 145, 219, 0.5);
        }
        100% {
            transform: scale(1);
            opacity: 1;
            background: rgba(11, 145, 219, 0.5);
        }
    }

    .cursor-grow-arrow {
        background: #D9D9D9;
        border-radius: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        top: var(--x, 100px);
        left: var(--y, 100px);
        pointer-events: none;
        transition: all 0.6s ease;
        transition-property: background, transform;
        transform-origin: 100% 100%;
        z-index: 12;
        animation: cursorGrow 0.2s ease-out forwards;
    }

    .cursor-grow-arrow::after {
        width: 100%;
        height: 100%;
        background: rgba(110, 110, 110, 0.5);
        position: absolute;
        border-radius: 50%;
        animation: rippleArrow 0.4s ease-out;
        transform-origin: center center;
        opacity: 1;
        display: flex;
        align-items: center;
        color: white;
        justify-content: center;
        font-family: "Poppins", sans-serif;
        font-size: 1rem;
        content: "<" "\00a0" "Drag" "\00a0" ">";
    }

    .cursor-white {
        width: 1rem;
        height: 1rem;
        background: white;
        border-radius: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        top: var(--x, 100px);
        left: var(--y, 100px);
        transition: all 0.6s ease;
        transition-property: background, transform;
        transform-origin: 100% 100%;
        z-index: 70;
        pointer-events: none;
    }


    @keyframes rippleArrow {
        0% {
            transform: scale(0);
            background: white;
        }
        20% {
            transform: scale(0);
            opacity: 0.1;
            background: white;
        }
        100% {
            transform: scale(1);
            opacity: 1;
            background: white;
        }
    }
}

.paralax-image {
    overflow: hidden;
    height: 200px;
    position: relative;
}

.paralax-image img {
    position: absolute;
    height: 100vh;
    min-width: 100%;
}

.parallax {
    background-image: url("http://s1.picswalls.com/wallpapers/2015/09/20/2015-wallpaper_111525594_269.jpg");
    min-height: 500px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.home-header-gradient {
    background: radial-gradient(circle at bottom, rgba(9, 145, 219, 0.6) 30%, rgba(9, 9, 58, 0.8) 38%);
    background-size: 200% 200%;
    animation: rotate-gradient 2s ease infinite;
    height: 100vh;
}

@keyframes rotate-gradient {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 50% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

@media only screen and (min-width: 600px) {
    .home-header-gradient {
        width: 100%;
        height: 100vh;
        color: #fff;
        background: radial-gradient(circle at top, rgba(9, 145, 219, 0.6) 1%, rgba(9, 9, 58, 0.8) 38%);
        position: relative;
        -webkit-animation: Gradient 9s linear infinite;
        -moz-animation: Gradient 9s linear infinite;
        animation: Gradient 9s linear infinite;
        filter: blur(9px);
        top: 0;
        left: 0
    }
}


@-webkit-keyframes Gradient {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@-moz-keyframes Gradient {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes Gradient {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.mask-image {
    -webkit-mask-size: contain;
    -webkit-mask-image: url('../../assets/svg/nebula.svg');
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: bottom;
}


@media only screen and (min-width: 900px) {
    .mask-image {
        height: 85vh;
        -webkit-mask-size: contain;
        -webkit-mask-image: url('../../assets/svg/nebula.svg');
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-position: bottom;
    }
}

.fade-in-text {
    animation: fadeIn 8s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.text-in-left {
    animation: leftToRight 2s;
}

@keyframes leftToRight {
    from {
        margin-left: -40%;
    }
    to {
        margin-left: 0;
    }
}

.slow-transition {
    white-space: nowrap;
    overflow: hidden;
    animation: animate 2s linear forwards;
}

@keyframes animate {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

.flicker {
    background: -webkit-gradient(linear, left top, right top, from(#e6e6e6), to(#1d1c1c), color-stop(0.8, #ffffff)) no-repeat;
    background: gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.8, #fff)) no-repeat;
    background-size: 110px 100%;
    -webkit-background-clip: text;
    background-clip: text;
    animation: flick 1.5s infinite;
    animation-delay: 0.8s;
}

@keyframes flick {
    0% {
        background-position: top left;
    }
    100% {
        background-position: top right;
    }
}

.blob {
    width: 500px;
    height: 350px;
    display: block;
    position: relative;
}

.first-blob {
    position: absolute;
    top: 0;
    -webkit-animation: moveFirst 10s infinite alternate;
    animation: moveFirst 10s infinite alternate;
    transition: 8s cubic-bezier(0.8, 0.8, 0.8, 0.8);
}

@-webkit-keyframes moveFirst {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    50% {
        transform: translate(60px, 0px) rotate(150deg);
    }
    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}

@keyframes moveFirst {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    50% {
        transform: translate(60px, 0px) rotate(150deg);
    }
    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}

.second-blob {
    position: absolute;
    top: 0;
    left: 50px;
    -webkit-animation: moveSecond 10s infinite alternate;
    animation: moveSecond 10s infinite alternate;
    transition: 8s cubic-bezier(0.7, 0.7, 0.7, 0.7);
}

@-webkit-keyframes moveSecond {
    0% {
        transform: translate(0px, 20px) rotate(20deg);
    }
    50% {
        transform: translate(-170px, -30px) rotate(180deg);
    }
    100% {
        transform: translate(0px, 20px) rotate(20deg);
    }
}

@keyframes moveSecond {
    0% {
        transform: translate(0px, 20px) rotate(20deg);
    }
    50% {
        transform: translate(-170px, -30px) rotate(180deg);
    }
    100% {
        transform: translate(00px, 20px) rotate(20deg);
    }
}

.contact-button {
    border: 0.7px solid currentColor;
    color: white;
    font-family: roboto;
    font-weight: 100;
    overflow: hidden;
    padding: 1rem 2rem;
    position: relative;
    text-decoration: none;
    transition: 0.2s transform ease-in-out;
    will-change: transform;
    z-index: 0;
    border-radius: 3rem;
}


.email-button {
    border: 0.7px solid currentColor;
    color: white;
    font-family: roboto;
    font-weight: 100;
    overflow: hidden;
    padding: 1rem 2rem;
    position: relative;
    text-decoration: none;
    transition: 0.2s transform ease-in-out;
    will-change: transform;
    z-index: 0;
    border-radius: 3rem;
}

.email-button::after {
    background-color: white;
    border-radius: 3rem;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(10deg);
    transform-origin: top left;
    transition: 0.2s transform ease-out;
    will-change: transform;
    z-index: -1;
}

.email-button:hover::after {
    transform: translate(0, 0);
}


.email-button:hover {
    border: 2px solid transparent;
    color: black;
    transform: scale(1.05);
    will-change: transform;
}

@media only screen and (min-width: 600px) {
    .row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }
}

@media only screen and (min-width: 1200px) {
    .row {
        align-items: stretch;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        padding: 40px 0;
    }
}

.card {
    margin-top: 2rem;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    width: 230px;
    color: white;
    height: 195px;
    background-color: #222;
    border-radius: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.card .img-box {
    position: absolute;
    top: 10px;
    left: 10px;
    background: rgba(255, 255, 255, 0.1);
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    z-index: 10;
    overflow: hidden;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
    transition: 0.5s ease-in-out;
    backdrop-filter: blur(20px);
    padding: 20px;
}

.icon {
    width: 2rem;
}

.card-text {
    font-size: 0.9rem;
    width: 140px;
}

.card .content {
    position: absolute;
    right: 0;
    width: calc(100% - 100px);
    height: 100%;
    padding-top: 2px;
    padding-left: 4px;
    margin-right: 9px;
    padding-right: 1px;
    display: inline-block;
    justify-content: center;
    align-items: center;
    align-self: center;
    font-size: 0.65rem;
}

.card:hover .card-text {
    font-size: 0.9rem;
    width: 140px;
}


.card:hover .icon {
    width: 2rem;
}

.card:hover .img-box {
    width: 165px;
    height: 150px;
    left: -75px;
    top: calc(50% - 75px);
    padding: 12px;
}

@media only screen and (min-width: 900px) {
    .card {
        margin-right: 5.5rem;
        flex-grow: 0;
        flex-shrink: 0;
        position: relative;
        width: 335px;
        color: white;
        height: 270px;
        background-color: #222;
        border-radius: 20px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    }

    .card .img-box {
        position: absolute;
        top: 10px;
        left: 10px;
        background: rgba(255, 255, 255, 0.1);
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        z-index: 10;
        overflow: hidden;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
        transition: 0.5s ease-in-out;
        backdrop-filter: blur(20px);
        padding: 20px;
    }

    .icon {
        width: 3.5rem;
        transition: 0.5s ease-in-out;
    }

    .card-text {
        font-size: 1.7rem;
        transition: 0.5s ease-in-out;
        width: 280px;
    }

    .card:hover .card-text {
        font-size: 0.9rem;
        width: 140px;
    }


    .card:hover .icon {
        width: 2rem;
    }

    .card:hover .img-box {
        width: 165px;
        height: 150px;
        left: -75px;
        top: calc(50% - 75px);
        padding: 12px;
    }

    .card .content {
        position: absolute;
        right: 0;
        width: calc(100% - 85px);
        height: 100%;
        padding: 20px;
        display: inline-block;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
    }
}

.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}

.burger {
    position: relative;
    display: block;
    width: 28px;
    height: 3.5px;
    margin: 0 auto;
    background: white;
    transform: skew(5deg);
    transition: all .275s;
}

.burger:after,
.burger:before {
    content: '';
    display: block;
    height: 100%;
    background: white;
    transition: all .275s;
}

.burger:after {
    transform: translateY(-12px) skew(-20deg);
}

.burger:before {
    transform: translateY(-16px) skew(-10deg);
}

.open-burger {
    position: relative;
    display: block;
    width: 28px;
    height: 4px;
    margin: 0 auto;
    background: white;
    transition: all .275s;
    transform: skew(5deg) translateY(-8px) rotate(-45deg);
}

.open-burger:after,
.open-burger:before {
    content: '';
    display: block;
    height: 100%;
    background: white;
    transition: all .275s;
}

.open-burger:before {
    transform: translateY(0px) skew(-10deg) rotate(75deg);
}

.open-burger:after {
    transform: translateY(-12px) translateX(10px) skew(-20deg);
    opacity: 0;
}

.main-nav-open {
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    text-align: center;
    background: #000002;
}

.main-nav-text-open {
    opacity: 1;
    animation: nav 0.4s ease-in-out forwards;
}

@keyframes nav {
    0% {
        transform: translateY(30px)
    }
    100% {
        transform: translateY(0px)
    }
}

.team-img {
    height: 380px;
    width: 270px;
    border: 1px solid black;
    position: relative;
}

.team-img:before {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 0;
    bottom: 0;
    transition: width 0.5s ease-in-out;
    background: radial-gradient(ellipse at -25% -10%, rgba(9, 145, 219, 0.5) 0%, transparent 42%, transparent 100%);
}

.team-img:hover:before {
    width: 100%;
}

.wrapper {
    display: flex;
    justify-content: center;
}

.team-container {
    display: grid;
    -webkit-overflow-scrolling: touch;
    grid-template-rows: repeat(2, auto);
    grid-auto-columns: 270px;
    grid-auto-flow: column;
    overflow-x: auto;
    gap: 40px;
    scrollbar-width: none;
}

.team-container::-webkit-scrollbar {
    display: none;
}

.blur-nav {
    backdrop-filter: blur(7px);
    background-color: rgba(40, 42, 47, 0.4);
}

.content div {
    -webkit-line-clamp: 10;
}

.fullPageSpinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: none;
}

.fullPageSpinner.remove {
    opacity: 0;
    pointer-events: none;
    transition: opacity 1.0s ease-in-out;
}